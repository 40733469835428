<template>
  <div v-if="isShow" class="page-rooter-fff" @touchstart.once="autoPlay()">
<!--    <nav-bar header-title="教练激励" backColor="#ffffff" :show-back="true" :header-back="appBack"/>-->
    <app-page id="box" class="page-img">
      <div class="page-h">
        <div class="top-btn">

          <div id="mc_play" :class="'music ' + (playing?'on':'stop')" @click="playMusic()">
            <audio id="music" ref="music" loop="loop">
              <source
                src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/music.mp3"
                type="audio/mpeg">
            </audio>
          </div>

          <img @click="showRule = true"
            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/3.png"
            alt="">
          <img @click="jumpList()"
               src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/4.png"
               alt="">
        </div>
        <div class="money">
          <div class="money-tips"></div>
          <div class="money-item">
            <div v-for="(item, index) in luckDrawSum" :key="index"><span>{{ item }}</span></div>
            <div class="unit">元</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-game">
          <div class="times">剩余次数：{{ times }}次</div>
          <div class="game-bg-box">
            <img class="game-bg" :class="shake?'heartBeat':''"
                 src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/game_bg2.png"
                 alt="">
            <img class="game-bg2" :class="shake?'shake':''"
                 src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/game_bg3.png"
                 alt="">
          </div>

          <div class="btn">
            <div @click="gameStart(1)"></div>
            <div @click="gameStart(3)"></div>
          </div>
        </div>
        <div class="title1">
          <img
            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/7.png"
            alt="">
        </div>

        <div class="nav">
          <div>获奖者</div>
          <div>获得奖品</div>
        </div>
        <div class="list" v-if="luckDrawListAll.length">
          <swiper :options="swipeOption" style="height: 100%">
            <swiperSlide v-for="(item, index) in luckDrawListAll" :key="index">
              <div class="item">
                <div class="d1">{{ item.teacherName }}</div>
                <div class="d2">{{ item.bonus }}</div>
              </div>
            </swiperSlide>
          </swiper>
        </div>
        <div class="list empty" v-else>
          <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/defaultPic/empty.png" alt="">
          <div>暂无数据</div>
        </div>
        <div class="bottom-text">
          <div>SUPER DEER</div>
        </div>
      </div>
    </app-page>
    <!--抽奖成功弹窗-->
    <van-popup v-model="showSuccess" :overlay="true" style="background-color: transparent"
               :close-on-click-overlay="false">
      <img v-if="num === 1" class="title-img" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/title1.png" alt="">
      <img v-else-if="num === 3" class="title-img" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/title3.png" alt="">
      <div class="pay-box">
        <div class="amount"><span>{{ LuckDrawMoney }}</span> 元红包</div>
        <div class="game-btn">
          <div @click="againGame(1)">再抽一次</div>
          <div @click="againGame(3)">再抽三次</div>
        </div>
      </div>
      <img @click="showSuccess = false" class="result-close"
           src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png"
           alt="">
    </van-popup>


    <!--规则弹窗-->
    <van-popup v-model="showRule" :overlay="true" style="background-color: transparent" :close-on-click-overlay="true">
      <div class="rule-popup-box">
        <div class="rule-popup-text">
          <p>1、【总奖池构成】活动期间，日授6课上榜教练总人次*10元记为累计总金额<br>
2、【抽红包时间】2024年6月19日至6月23日晚24点，活动时间结束后，未抽完的红包，系统将自动代抽;<br>
3、【抽红包资格】在2024年4月20日至5月20日期间，上榜"日授6课”优秀奖的教练；根据教练上榜次数，获得对应抽红包次数;<br>
4、红包为系统随机手气派发，以工资的形式下月发放</p>
        </div>
      </div>
      <img @click="showRule = false" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="">
    </van-popup>
  </div>
  <div v-else class="row-center-center" style="height: 100vh">
      <van-empty description="暂无活动" />
  </div>
</template>

<script>
import appMixin from "@/mixin/appMixin";
import appPage from "@/common/components/appPage";
import {appGetTeacherId, appGetTeacherToken, appGetUserId, getUserIdAndToken, coachAppOpenWeb} from "@/lib/appMethod";
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  data() {
    return {
      param: {userId: '', token: ''}, // 请求参数
      showSuccess: false,
      showRule: false,
      luckDrawSum: "00000", // 总奖池
      times: 0, // 抽奖次数
      luckDrawListAll: 0, // 获奖名单列表
      LuckDrawMoney: 0, // 获得红包金额
      num: 1, // 一次抽奖次数 1 一次 3 三次
      shake: false, // 是否显示动画
      isReady: true,
      playing: false,
      isEnd: false,
      isShow: false, // 是否有参与活动资格
      swipeOption: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        // loop: true,
        direction: "vertical",
        slidesPerView: 5,
        speed: 400,
      }
    };
  },
  mixins: [appMixin],
  components: {
    appPage,
    swiper,
    swiperSlide
  },
  created() {
    this.load();
  },
  mounted() {
    document.addEventListener("visibilitychange",  () => {
      if (document.visibilityState === "visible") {
        if(this.teacherId){
          this.getPerTeacherLuckDrawSum()
          this.getSurplusFrequency()
          this.getPerTeacherLuckDrawListAll()
        }
      }
      if (document.visibilityState === "hidden") {
        this.$refs.music.pause()
        this.playing = false
      }
    })
  },
  methods: {
    // 页面初次加载
    async load() {
      if (this.appTypeStr === "ios" || this.appTypeStr === "and") {
        try {
          this.appCommParams = await this.getAppParams2([
            appGetUserId(),
            appGetTeacherToken(),
            appGetTeacherId(),
          ]);
          this.userId = this.appCommParams[0];
          this.token = this.appCommParams[1];
          this.teacherId = this.appCommParams[2] || null;
          console.log(555555)


          // this.teacherId = '870973349583392768'
        } catch (e) {
          console.log('不是APP环境环境错误')
        }
        // 判断是否有权限进入
        let isShow = await this.hasActivity()
        if(!isShow.data){
           this.isShow = false
           return false
        }
        this.isShow = true


        this.getPerTeacherLuckDrawSum()
        this.getSurplusFrequency()
        this.getPerTeacherLuckDrawListAll()
      }

    },
    async getAppParams2(promiseArr) {
      return await Promise.all(promiseArr);
    },
    async hasActivity(promiseArr) {
        return  this.$axios.post(`${this.baseURLApp}/perTeacherLuckDraw/hasActivity`, {teacherId: this.teacherId})
    },
    jumpList() {
      // this.$router.push({path: '/superdeer-activity/pri-incentive-2021/list?isBackToH5=true&teacherId='+this.teacherId})
      console.log(111111)
      coachAppOpenWeb('我的红包', `${window.location.origin}/#/superdeer-activity/pri-incentive-2021/list?isBackToH5=true&teacherId=${this.teacherId}`, '1')


    },
    againGame(num) {
      // this.showSuccess = false
      if(this.times < num){
        this.$toast({
          message: `抽奖次数不足${num}次！`,
          duration:1500
        })
        return false
      }
      setTimeout(() => {
        this.gameStart(num)
      },500)
    },
    gameStart(num) {
      if(this.times < num){
        this.$toast({
          message: `抽奖次数不足${num}次！`,
          duration:1500
        })
        return false
      }
      if (this.isReady) {
        this.isReady = false
        this.showSuccess = false
        this.$axios.post(`${this.baseURLApp}/perTeacherLuckDraw/getPerTeacherLuckDrawResult`, {
          teacherId: this.teacherId,
          num
        }).then(res => {
          this.getPerTeacherLuckDrawSum()
          this.getSurplusFrequency()
          this.getPerTeacherLuckDrawListAll()
          this.shake = true
          this.LuckDrawMoney = res.data.toFixed(2)
          this.num = num
          try {
            navigator.vibrate = navigator.vibrate ||
              navigator.webkitVibrate ||
              navigator.mozVibrate ||
              navigator.msVibrate;
            navigator.vibrate(200);   //ios有兼容性
          } catch (e) {
            console.log('兼容性问题', e)
          }
          setTimeout(() => {
            this.showSuccess = true
            this.isReady = true
            this.shake = false
          }, 1200)
        }).catch(res => {
          this.isReady = true
        })
      }


    },
    playMusic() {
      if (this.playing) {
        this.playing = false
        this.$refs.music.pause()
      } else {
        this.playing = true
        this.$refs.music.play()
      }
    },
    autoPlay() {
      console.log(this.$refs.music)
      if (this.$refs.music.paused) {
        this.$refs.music.play()
        setTimeout(()=>{
          if (!this.$refs.music.paused) {
            this.playing = true
          }
        },200)
      }else{
        this.playing = true
      }
    },
    // 获取总奖池
    getPerTeacherLuckDrawSum(){
      this.$axios.post(`${this.baseURLApp}/perTeacherLuckDraw/perTeacherLuckDrawSum`, {
        teacherId: this.teacherId
      }).then(res => {
        let str = "00000", num = res.data.toString()
        if(num){
          str = str.slice(num.length)
          this.luckDrawSum = str+num
        }
      })
    },
    // 获取教练剩余次数
    getSurplusFrequency() {
      this.$axios.post(`${this.baseURLApp}/perTeacherLuckDraw/surplusFrequency`, {
        teacherId: this.teacherId
      }).then(res => {
        this.times = res.data ?? 0
        // this.times = 100
      })
    },
    //获取获奖名单列表
    getPerTeacherLuckDrawListAll() {
      this.$axios.post(`${this.baseURLApp}/perTeacherLuckDraw/getPerTeacherLuckDrawListAll`, {
        teacherId: this.teacherId
      }).then(res => {
        this.luckDrawListAll = res.data ?? []
      })
    }
  },
};
</script>
<style lang="less" scoped>
@import "./index";
</style>
