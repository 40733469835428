<template>
  <div class="page-rooter-fff">
<!--    <header :style="this.appTypeStr === 'and'? 'padding-top: calc('+ barHeight +'px + 4.27vw);':''">-->
<!--      <div @click="appBack" :style="this.appTypeStr === 'and'? 'top: calc('+ barHeight +'px + 4.27vw);':''"-->
<!--           class="back">-->
<!--        <van-icon name="arrow-left"/>-->
<!--      </div>-->
<!--      <div class="title">我的红包</div>-->
<!--    </header>-->
    <div class="list">
      <div class="nav-title">
        <div class="r1">红包金额</div>
        <div class="r2">获得时间</div>
        <div class="r3">领取状态</div>
      </div>
      <div class="item-box" v-if="list.length">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="r1">{{ item.bonus }}</div>
          <div class="r2">{{ item.extractDate }}</div>
          <div class="r3" v-if="item.giveStatus === '0'">待发放</div>
          <div class="r3" v-else style="color: #FF7024">已发放</div>
        </div>
      </div>
      <div class="item-box empty" v-else>
        <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/defaultPic/empty.png" alt="">
        <div>暂无数据</div>
      </div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
import appMixin from "@/mixin/appMixin";
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import {getUserIdAndToken, getBarHeight} from "../../../lib/appMethod";

export default {
  data() {
    return {
      barHeight: 0,
      list: []
    };
  },
  mixins: [appMixin],
  created() {
    this.load();
    if (this.appTypeStr === 'and')
      this.barHeight = getBarHeight()
  },
  methods: {
    // 页面初次加载
    async load() {
      this.teacherId = this.$route.query.teacherId
      this.getPerTeacherLuckDrawList()
    },
    // 获取教练红包列表
    getPerTeacherLuckDrawList() {
      this.$axios.post(`${this.baseURLApp}/perTeacherLuckDraw/getPerTeacherLuckDrawList`, {
        teacherId: this.teacherId
      }).then(res => {
        this.list = res.data ?? []
        /**
         * bonus  奖金  string
         extractDate  抽取时间  string
         giveStatus  发放状态 0待发放 1已发放  string
         teacherName
         */
      })
    },
  },
};
</script>
<style lang="less" scoped>
.page-rooter-fff {
  background: #F9585A url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/double12-2021/pri-incentive/list1.png") center top no-repeat;
  background-size: 750px 366px;
  min-height: 100vh;

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 36px;
    position: absolute;
    width: 686px;
    padding: calc(env(safe-area-inset-top) + 32px) 32px 32px;

    .back {
      height: 48px;
      width: 48px;
      font-size: 48px;
      position: absolute;
      left: 32px;
      top: calc(env(safe-area-inset-top) + 32px);
    }

    .title {
      width: 580px;
      text-align: center;
    }
  }
}

.list {
  height: 85vh;
  width: 686px;
  margin: 8vh auto 0;
  border-radius: 32px;
  background: linear-gradient(180deg, #FFEBEB 0%, #FFBEBE 100%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  .nav-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #D63333;
    height: 106px;
    box-sizing: border-box;
    font-size: 28px;
    padding: 32px 28px;
    border-bottom: 2px #D36F61 dashed;
    font-weight: bold;

    .r2 {
      text-align: center;
    }

    flex-shrink: 0;
  }

  .bottom {
    height: 150px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 196, 196, 0) 0%, rgba(255, 196, 196, .5) 30%, #FFC4C4 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 32px 32px;
  }

  .item-box {
    flex-grow: 1;
    overflow-y: scroll;
    &.empty{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img{
        width: 160px;
        height: 160px;
      }
      div{
        margin-top: 12px;
        font-size: 24px;
        color: #999;
      }
    }
    &::-webkit-scrollbar {
      background: transparent;
      width: 0;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    color: #BE2525;
    font-size: 26px;
    line-height: 32px;
    padding: 20px 28px 26px;
    align-items: center;

    .r2 {
      text-align: center;
    }

    .r3 {
      text-align: right;
    }
  }

  .r1, .r2, .r3 {
    width: 160px;
  }

  .r3 {
    text-align: right;
  }
}
</style>
